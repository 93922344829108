<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div
          @click="changeStatus(index)"
          class="li"
          :class="{ active: index == statusIndex }"
          v-for="(item, index) in 3"
          :key="index"
        >
          {{ index == 0 ? "审核中" : index == 1 ? "审核成功" : "审核失败" }}
        </div>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--honor-head start-->
    <div class="honor-head">
      <div class="head-nav">
        <div @click="changeType(0)" :class="{active:0 == typeIndex}" class="li nav-link">我的审核</div>
        <div v-if="userInfo.position == 1" @click="changeType(1)" :class="{active:1 == typeIndex}" class="li nav-link">审核他人</div>
      </div>
    </div>
    <!--honor-head end-->
    <!--ind-main start-->
    <div class="ind-main" v-if="list.length">
      <!--audit-list start-->
      <div class="audit-list">
        <!--audit-item start-->
        <div class="audit-item" v-for="(item,index) in list" :key="index">
          <div class="item-l">
            <div class="item-flex">
              <div class="item-td">
                <span class="color-9">小区名称：</span>{{ item.name }}
              </div>
              <div class="item-td">
                <span class="color-9">区域：</span>{{ item.area }}
              </div>
              <div class="item-td">
                <span class="color-9">详细地址：</span>{{ item.address }}
              </div>
              
            </div>
            <div class="" v-if="item.status == 2 && typeIndex == 1">
                <span class="color-9">失败原因：</span>{{ item.audit_remark }}
              </div>
          </div>
          <div class="item-r" v-if="statusIndex == 0 && typeIndex == 0">
            <el-button class="audit-list-btn red" @click="cancelPlot(item,1)">取消</el-button>
          </div>
          <div class="item-r flexs" v-else-if="statusIndex == 0 && typeIndex == 1">
            <el-button class="audit-list-btn red" @click="cancelPlot(item,2)">拒绝</el-button>
            <el-button class="audit-list-btn blue" @click="cancelPlot(item,3)">同意</el-button>
          </div>
        </div>
        <!--audit-item end-->
      </div>
      <!--audit-list end-->
      <div class="paging" v-if="list.length">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--ind-main end-->
    <audit-pop-re ref="popr" @succend="init"></audit-pop-re>
  </div>
</template>

<script>
import AuditPopRe from '../../components/AuditPopRe.vue';
import { mapGetters } from "vuex";
export default {
  components:{AuditPopRe},
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      statusIndex: 0, // 状态：0 审核中；1 审核通过；2 审核失败
      typeIndex: 0 //身份：1 我的；2 他人
    };
  },
  methods: {
    //取消审核
    cancelPlot (item,type) {
      if (type == 1) {
        this.$confirm('是否取消?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.cancelPlot({
            id:item.id
          }).then(res=>{
            this.init()
            this.$message({
              type: 'success',
              message: res.msg
            });
          })
        }).catch(() => {
                
        });
      } else if (type == 2) {
        // 
        this.$refs.popr.open(item.id,6)
      } else {
        this.$axios.agreePlot({id:item.id}).then(res=>{
          this.init()
          this.$message({
            type: 'success',
            message: res.msg
          });
        })
      }
      
    },
    //切换身份
    changeType (index) {
      this.typeIndex = index
      this.init()
    },
    //切换状态
    changeStatus (index) {
      this.typeIndex = 0
      this.statusIndex = index
      this.init()
    },
    changePage (e) {
      this.page = e
      this.getxQuList()
    },
    init () {
      this.page = 1
      this.list = []
      this.getxQuList()
    },
    //获取小区
    getxQuList() {
      this.$axios
        .xQuList({
          page:this.page,
          status: this.statusIndex,
          role_type: this.typeIndex + 1,
        })
        .then((res) => {
          this.list = res.data.data
          this.total = res.data.total
        });
    }
  },
  created() {
    this.getxQuList();
  }
};
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #ebebeb;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}
.message-select {
  /deep/ .el-select {
    width: 100px;
    min-width: 100px !important;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
  }
}

.honor-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background: #fff;
  margin-bottom: 10px;
  align-items: center;
  .head-nav {
    display: flex;
  }
  .li {
    margin-right: 55px;
    line-height: 36px;
  }
  .nav-link {
    cursor: pointer;
    font-size: 16px;
    color: #666;
    line-height: 36px;
  }
  .active {
    color: #3273f6;
  }
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.ind-add-btn-o {
  width: 88px;
  height: 36px;
  border-radius: 4px;
  background: #ffa740;
  padding: 0px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
  border: none;
}
.ind-main {
  background: #fff;
  padding: 20px 40px;
}
.record_table {
  display: table;
  width: 100%;
  margin: 00px 0px;
}
.record_tr {
  display: table-row;
}
.record_td {
  display: table-cell;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 1.8;
  vertical-align: middle;
}
.record_td .td {
  line-height: 1.8;
}
.record_tr:nth-child(2n) {
  background: #f7fcff;
}
.audit-list {
  .audit-list-btn {
    height: 36px;
    border-radius: 4px;
    padding: 0 24px;
    font-size: 16px;
    line-height: 36px;
    border: none;
    outline: none;
    position: relative;
    z-index: 11;
  }
  .audit-list-btn.blue {
    background: #3273f6;
    color: #fff;
  }
  .audit-list-btn.red {
    background: #fddfdf;
    color: #ff5151;
  }
  .audit-item {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #ebebeb;
    .item-flex {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .item-td {
      font-size: 16px;
      margin: 5px 0;
      line-height: 1.6;
      min-width: 40%;
    }
    .item-l {
      width: calc(100% - 150px);
    }
    .item-date {
      font-size: 14px;
      color: #999;
      min-width: 120px;
      text-align: right;
    }
  }
}
</style>
