// 借钥匙
<template>
  <div class="examRule">
    <el-dialog title="" :show-close="false" :close-on-click-modal="false" :visible.sync="dialogVisible" width="600px">
      <div class="examRule_head flex">
        <span>拒绝原因</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-40 p-b-30 p-l-40">
        <div class="borrow-form">
          <el-form ref="form" :rules="rules" class="form" :model="form" label-width="100px">
            <el-form-item label="" label-width="0" prop="remark" v-if="type == 1 || type == 6 || type == 7">
              <div class="audit-text">
                <el-input type="textarea" :rows="5" placeholder="请输入拒绝原因" v-model="form.remark">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="" label-width="0" prop="bo_text" v-else-if="type == 2 || type == 5">
              <div class="audit-text">
                <el-input type="textarea" :rows="5" placeholder="请输入拒绝原因" v-model="form.bo_text">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="" label-width="0" prop="fail_desc" v-else-if="type == 3 || type == 4 || type == 8">
              <div class="audit-text">
                <el-input type="textarea" :rows="5" placeholder="请输入拒绝原因" v-model="form.fail_desc">
                </el-input>
              </div>
            </el-form-item>
          </el-form>
          <div class="interview_main_footer center">
            <el-button @click="submit('form')">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 1,//房源删除2报告删除3请假申请4信息审核客源5合同拒绝6小区审核7晋升审核8客源删除
      dialogVisible: false,
      content: "", //规则
      form: {
        id: '',
        bo_text: '',
        remark: '',
        fail_desc: '',
      },
      rules: {
        bo_text: [
          { required: true, message: '请输入拒绝原因', trigger: 'blur' },
        ],
        remark: [
          { required: true, message: '请输入拒绝原因', trigger: 'blur' },
        ],
        fail_desc: [
          { required: true, message: '请输入拒绝原因', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let api = ''
          switch (this.type) {
            case 1:
              api = 'rejectHouseDel'
              break;
            case 2:
              api = 'baoGaoDealJ'
              break;
            case 3:
              api = 'rejectAskLeave'
              break;
            case 4:
              api = 'jvjClients'
              break;
            case 5:
              api = 'shenHeContractJ'
              break;
            case 6:
              api = 'rejectPlot'
              break;
            case 7:
              api = 'rejectPromote'
              break;
            case 8:
              api = 'rejectPassenger'
              break;
            default:
              break;
          }
          this.$axios[api](this.form).then(res => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.form.bo_text = ''
            this.form.remark = ''
            this.form.fail_desc = ''
            this.$emit('succend')
            this.dialogVisible = false;
          })
        } else {
          return false;
        }
      });
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id, type) {
      this.type = type
      this.form.id = id
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;

  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}

/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

.interview_main_footer {
  padding: 30px 0 40px 0;

  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}

.audit-text {
  /deep/ .el-textarea__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
</style>
